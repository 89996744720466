@import "react-phone-number-input/style.css";

:root {
  --interFont: "Inter", sans-serif;
  --newsReaderFont: "Newsreader", serif;
  --greenish: #61beb0;
  --lightpink: #f9f8fe;
  --darkBlue: #3f4afe;
  --lightBlue: #797dfc;
  --grayish: #8f959c;
  --fontsize18: 18px;
  --lightgreen: #afeeee;
  --green: #c6ffcd;
  --skyColor: #61beb0;
}
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: var(--interFont);
}

::selection {
  background-color: var(--lightgreen);
  color: #000;
}

::-webkit-scrollbar {
  display: none;
}
.bg-sky {
  background-color: var(--skyColor) !important;
}
.text-sky {
  color: var(--skyColor) !important;
}

select:disabled {
  opacity: 0 !important;
}
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
.sticky-top {
  z-index: 50 !important;
}
.check {
  width: 24px;
  height: 24px;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.coming_soon_wrapper {
  height: 80vh;
}

.login_wrapper {
  min-height: calc(100vh - 285px);
}

.cursor-pointer {
  cursor: pointer !important;
}
.cursor-disable {
  cursor: not-allowed !important;
}

.login_container {
  box-shadow: 0 0 29px rgba(0, 0, 0, 0.1);
}

.eyeWrapper {
  top: 8px;
}

.phone_wrapper_signup .PhoneInput {
  border-radius: 15px;
  border: 1px solid rgba(0, 0, 0, 0.175);
  /* margin-bottom: 17px; */
}
.phone_wrapper_signup .PhoneInputCountry {
  margin-left: 10px !important;
}
.phone_wrapper_signup .PhoneInputInput {
  padding: 8px 10px;
  border-radius: 15px;
  border: none !important;
  /* margin-bottom: 17px; */
}
.phone_wrapper_signup .PhoneInput--focus:focus-within {
  border: 1px solid var(--greenish) !important;
}
.phone_wrapper_signup .PhoneInput:focus-visible {
  border: none !important;
  /* margin-bottom: 17px; */
}
.phone_wrapper_signup .PhoneInput--focus :focus-visible {
  outline: none !important;
}

.form-control {
  background-clip: padding-box;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-control:focus {
  background-color: #fff !important;
  border-color: var(--greenish) !important;
  outline: 0 !important;
  box-shadow: none !important;
}

.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: none !important;
}
.top-dropdow {
  position: relative;
}
.top-dropdown:hover .dropdown-box {
  display: grid !important;
  width: 100%;
}
.dropdown-box {
  position: absolute;
  background: #ffffff;
  max-width: 236px;
  display: none;
  z-index: 2;
  padding: 14px 25px 10px;
  box-shadow: 0px 0px 10px 0px #c6c6c638;
}
.dropdown-box a {
  border-bottom: 1px solid #e9e9e982 !important;
  padding: 2px 0;
}
.dropdown-box a:last-child {
  border: none !important;
}
.mainlogo {
  max-width: 113px;
}
.line-clamp-para-1 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 1;
  white-space: normal;
}
.line-clamp {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 3;
  white-space: normal;
}
.line-clamp-para {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 6;
  white-space: normal;
}

.fs-18 {
  line-height: 31px;
  font-size: var(--fontsize18) !important;
}

.text-light-blue {
  color: var(--lightBlue) !important;
}

.text-gray {
  color: var(--grayish) !important;
}

.text-blue {
  color: var(--darkBlue) !important;
}

.bg-lightpink {
  background-color: var(--lightpink) !important;
}
.bg-lightgreen {
  background-color: var(--lightgreen) !important;
}

.bg-gray {
  background-color: var(--grayish) !important;
}

.text-greenish {
  color: var(--greenish) !important;
}

.bg-greenish {
  background-color: var(--greenish) !important;
}
.bg-green {
  background-color: var(--green) !important;
}
.newsReaderFont {
  font-family: var(--newsReaderFont);
}

.interFont {
  font-family: var(--newsReaderFont);
}

.about-img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.accordion-item:last-of-type .accordion-button.collapsed {
  border-radius: 0 !important;
}

.accordion-button:not(.collapsed)::after {
  transform: none !important;
}

.accordian-wrapper .accordion-item {
  border: none;
  background-color: #f8f9fa !important;
}

.accordian-wrapper .accordion-header {
  font-size: 40px;
  position: relative;
}

.accordian-wrapper .accordion-header button {
  font-size: 18px;
  line-height: 31px;
  font-weight: 400;
  box-shadow: none !important;
  color: black !important;
  padding: 12px 0px;
  border-bottom: 1px solid;
}

.accordian-wrapper .accordion-button:not(.collapsed) {
  background: transparent;
  box-shadow: none;
  color: #000;
}

.accordian-wrapper .accordion-body {
  font-size: 18px;
  line-height: 28px;
  padding: 12px 0px;
}

.accordion-button {
  background-color: #f8f9fa !important;
}
.accordion-button::after {
  content: "+" !important;
  background-image: none !important;
  padding: 0px;
  text-align: center;
}

.accordion-button:not(.collapsed)::after {
  content: "-" !important;
  color: #000;
  padding: 0px !important;
  text-align: center;
  font-size: 35px !important;
}

.partner_logos img,
.partner_logos1 img {
  width: auto;
  height: 95px;
  margin: auto;
}

.partner_logos {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  row-gap: 35px;
  column-gap: 12px;
}
.partner_logos1 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  row-gap: 35px;
  column-gap: 12px;
}

.footerBottom a {
  color: #000 !important;
}
.footerBottom a:hover {
  border-bottom: 3px solid var(--skyColor);
  color: var(--skyColor) !important;
  padding-bottom: 6px;
  transition: 0.4s all;
}
.slick-next {
  top: 50% !important;
}

.slick-prev {
  top: 50% !important;
}

.slick-next:before,
.slick-prev:before {
  font-size: 29px !important;
  opacity: 1 !important;
  color: #000 !important;
}

.modalwrapper {
  max-width: 800px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  border: none;
  outline: none;
  padding: 0 10px;
}

button.modalClose {
  align-self: end;
  font-size: 33px;
  background-color: transparent;
  border: none;
  color: white;
}

.WhyStudy_cards {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.alt_ContentImgEven > .row {
  flex-direction: row-reverse !important;
}

.test_prep_card {
  min-height: 153px !important;
}

.book_event_button {
  position: absolute;
  bottom: 25px;
  right: 25px;
}

.event-grid-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 30px;
  align-items: stretch;
}

.event-desc {
  gap: 10px;
}
.events_image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
  width: 100% !important;
  height: 190px !important;
}
.video_card .slick-track {
  display: flex;
  gap: 10px;
}
.video_testimonial .slick-prev {
  left: -30px !important;
}
.video_testimonial .slick-next {
  right: -30px !important;
}
.video_card .slick-list {
  margin-left: 10px;
}
.video_card {
  max-width: 90%;
  margin: auto;
}
.bg_banner_color {
  background-image: url("../public/images/events.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 486px;
}
.grey-bg {
  width: 50%;
}

.grey-bg:before {
  content: "";
  position: absolute;
  top: 0;
  left: -10%;
  background: #000000e3;
  width: 110%;
  height: 100%;
  z-index: 0;
  transform: skewX(10deg);
}

.search-accordinV2 .accordion-button:focus {
  box-shadow: none !important;
}
.search-accordinV2 .accordion-item:last-of-type .accordion-button.collapsed {
  border-radius: 6px !important ;
}

.search-accordinV2 .accordion-button:not(.collapsed)::after {
  content: "⌄" !important;
  font-size: 28px;
  transform: rotate(181deg) !important;
  position: absolute !important;
  right: 20px !important;
  top: 29px !important;
  transition: none !important;
}

.search-accordinV2 .accordion-button::after {
  top: 4px !important;
  content: "⌄" !important;
  position: absolute !important;
  right: 22px !important;
  font-size: 28px !important;
  transition: none !important;
}
.search-accordinV2 .accordion-button:not(.collapsed) {
  color: var(--greenish) !important;
}
.grid_search {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 30px;
}
.grid_course_search{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 30px;
}
.search_input input:focus-visible {
  outline: none !important;
}
/* .grid_search > div {
  display: grid;
  grid-template-columns: 1fr 1fr;
} */
.grid_search img {
  object-fit: cover;
  height: 200px !important;
}
.stats_logos {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
}

.img_container {
  height: 193px !important;
  /* width: 82% !important; */
}

.scholar-accordin .accordion {
  --bs-accordion-border-width: 0 !important;
}

.scholar-accordin .accordion-button:focus {
  box-shadow: none !important;
}

.scholar-accordin .accordion-item:last-of-type .accordion-button.collapsed {
  border-radius: 6px !important ;
}

.scholar-accordin .accordion-button:not(.collapsed)::after {
  content: "⌄" !important;
  font-size: 28px;
  transform: rotate(181deg) !important;
  position: absolute !important;
  right: 20px !important;
  top: 29px !important;
  transition: none !important;
}

.scholar-accordin .accordion-button::after {
  top: 4px !important;
  content: "⌄" !important;
  position: absolute !important;
  right: 22px !important;
  font-size: 28px !important;
  transition: none !important;
}

.scholar-accordin .accordion-button:not(.collapsed) {
  color: var(--skyColor) !important;
}

.scholar-accordin .accordion-button {
  background-color: white !important;
}

.scholar-accordin .accordion-item {
  border: none !important;

  box-shadow: 0px 0px 11px 0px #f5f5f5 !important;
}

.scholar-accordin .accordion-button:not(.collapsed)::after {
  top: 38px !important;
}

.scholar-accordin .accordion-button::after {
  top: 16px !important;
}

.scholar-accordin .accordion-body {
  border-top: 1px solid #73757188;
  margin: 5px !important;
}

.profile_img {
  object-fit: cover;
  object-position: center;
  max-width: 125px;
  max-height: 125px;
}

.message_textSize {
  font-size: 15px;
}

.ReactModal__Overlay.ReactModal__Overlay--after-open {
  background: rgb(0 0 0 / 75%) !important;
  z-index: 999 !important;
}

.search_input {
  background: #f1f1f1;
  padding: 8px 15px;
  border-radius: 8px;
}

.search_input input {
  width: 300px;
  padding: 0 !important;
  background: transparent;
}

/* #filterlist {
  position: absolute;
  top: 0;
  right: -100%;
  height: 100vh;
  width: 100%;
  z-index: 10;
} */

#filterlist {
  position: absolute;
  top: 0;
  z-index: 10;
  right: -100%;
}

#sidebar {
  width: 360px;
  margin: 0 0 0 auto;
  background-color: white;
  padding: 0px 21px;
  height: 100%;
  border: 1px solid rgba(238, 228, 228, 0.575);
  box-shadow: 0px 0px 2px 0px #f5f5f5 !important;
}

.sidebar_wrapper {
  height: calc(100vh - 128px);
  overflow: auto;
}

.overlayz {
  position: relative;
}

.overlayz:before {
  content: "";
  position: absolute;
  inset: 0;
  background: black;
  opacity: 0.5;
  z-index: 1;
}

.separater {
  width: 100px;
  height: 2px;
  background: var(--skyColor);
  margin-bottom: 20px;
  position: relative;
  margin-left: 20px;
}

.separater:before {
  position: absolute;
  top: -4px;
  left: -20px;
  content: "";
  background: var(--skyColor);
  width: 10px;
  height: 10px;
  transform: rotate(45deg);
}

.logout-toggle .dropdown-toggle::after {
  display: none !important;
}

.studentsCard {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 22px;
}

.card_box {
  /* box-shadow: 0px 0px 14px 1px #f7f7f7; */
  box-shadow: 07px 6px 11px 3px #f2f2f2;
  border: 2px solid white;
}

.card_box:nth-child(even):hover {
  border: 2px solid var(--lightgreen);
}

.card_box:nth-child(odd):hover {
  border: 2px solid var(--green);
}

.card_box svg {
  border-radius: 10px;
  padding: 15px;
  width: 58px;
  height: 58px;
  color: black;
}

.card_box:nth-child(even) svg {
  background: var(--lightgreen) !important;
}

.card_box:nth-child(odd) svg {
  background: var(--green) !important;
}

.scholar-accordin-main {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
}
.scholar-Admission {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}
.list-main li::marker {
  color: var(--greenish);
  font-size: 20px;
}

.page_index {
  z-index: 0 !important;
}

.aboutWrapper > div:first-child {
  padding-top: 30px !important;
}

.search_card_wrapper p span {
  color: #818181;
}

/* .loader_wrapper{
  padding-top: 400px;
} */

.search_card_wrapper p {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px dashed #e8e8e8;
  padding: 5px 0px;
}

.border_rank {
  border-bottom: 1px dashed #e8e8e8;
}
.border_rank div {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.loader {
  width: 50px;
  padding: 8px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: var(--skyColor);
  --_m: conic-gradient(#0000 10%, #000), linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
  mask: var(--_m);
  -webkit-mask-composite: source-out;
  mask-composite: subtract;
  animation: l3 1s infinite linear;
}

.get-in-touch .dropdown-toggle::after {
  display: none !important;
}

.get-in-touch {
  background-color: var(--green);
}

.faqs_accordin .accordion-button::after {
  top: 4px !important;
  content: "⌄" !important;
  position: absolute !important;
  right: 22px !important;
  font-size: 28px !important;
  transition: none !important;
}

.faqs_accordin .accordion-button:not(.collapsed) {
  color: var(--skyColor) !important;
}

.faqs_accordin .accordion-button {
  background-color: white !important;
}

.faqs_accordin .accordion-item {
  border: none !important;
  box-shadow: 0px 0px 11px 0px #f5f5f5 !important;
}

.faqs_accordin .accordion-button:not(.collapsed)::after {
  top: 33px !important;
  content: "⌄" !important;
  font-size: 28px;
  transform: rotate(181deg) !important;
  position: absolute !important;
  right: 20px !important;
  transition: none !important;
}

.faqs_accordin .accordion {
  --bs-accordion-border-width: 0 !important;
}

.faqs_accordin .accordion-button:focus {
  box-shadow: none !important;
}

.faqs_accordin .accordion-button::after {
  top: 6px !important;
}

.faqs_accordin .accordion-body {
  border-top: 1px solid #73757188;
  margin: 5px !important;
}

.course_wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
}
.parent_wrapper > div {
  height: 100%;
}
@keyframes l3 {
  to {
    transform: rotate(1turn);
  }
}

.cookie-section {
  position: relative;
  z-index: 50;
}

.cookie-banner {
  position: fixed;
  padding: 25px 15px;
  bottom: 25px;
  right: 25px;
  max-width: 500px;
  background-color: #f8f8f8;
  text-align: center;
  box-shadow: 0 0 2px 0 #b2b2b2;
}

.dropdown-box > a:hover {
  color: var(--greenish) !important;
}

.contact-details svg {
  background: whitesmoke;
  width: 45px;
  height: 45px;
  border-radius: 8px;
  padding: 10px;
  margin-right: 5px;
}

.contact-details > div:nth-child(even) svg {
  background: var(--lightgreen) !important;
}

.contact-details > div:nth-child(odd) svg {
  background: var(--green) !important;
}

.admin_details_card {
  grid-template-columns: 1fr 1fr 1fr 1fr;
  top: 37px;
  gap: 17px;
}

.admin_details_card > div {
  background-color: white;
  box-shadow: 0px 3px 30px 4px #00000012;
  min-height: 146px;
}

.card_container {
  background-color: var(--skyColor);
  margin-bottom: 87px !important;
}

.overlay-wrapper img {
  object-fit: cover;
  min-height: 431px;
}

.contact_wrapper {
  background: #00000080;
  position: absolute;
  bottom: 0;
  padding: 30px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  flex-wrap: wrap !important;
  row-gap: 32px;
  column-gap: 22px;
}

.table_heading th {
  background-color: #7c8987ab !important;
  color: #fff !important;
  font-size: 14px;
  padding: 15px !important;
}

.table_heading td {
  font-size: 14px;
  padding: 15px !important;
}

.table_heading {
  box-shadow: 0px 0px 10px 1px #87878721;
}

.table_heading tr:last-child td {
  border-bottom: none !important;
}

.list_items_wrapper {
  grid-template-columns: 1fr;
  gap: 11px;
  max-width: 600px;
}

.list_drop_wrapper {
  grid-template-columns: 1fr;
  gap: 11px;
  max-height: 249px;
  overflow-y: auto;
  overflow-x: hidden !important;
}
.list_drop_wrapper::-webkit-scrollbar {
  width: 10px !important;
  display: block !important;
  overflow-y: scroll !important;
  border-radius: 5px;
  background-color: #ceb6b634 !important;
}
.list_drop_wrapper::-webkit-scrollbar-thumb {
  background-color: var(--skyColor);
  border-radius: 5px;
}

.document_wrapper > div {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 23px;
  padding: 22px;
}

.drag_wrapper {
  min-width: 350px;
  background: #f5fbff;
  border-radius: 10px;
  border: 2px dashed #5f5f5f;
  margin: 0 auto;
  padding: 21px;
}

.drag_wrapper label input {
  opacity: 0 !important;
}

.document_container {
  grid-template-columns: 1fr 1fr;
}
.conductby {
  font-size: 12px;
}
.link_wrap small {
  word-break: break-all;
}
.link_wrap svg {
  min-width: 22px;
}

.course_wrapper_form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 40px;
  column-gap: 52px;
}
.course_wrapper_form .drag_wrapper {
  min-width: 100%;
  background: #f5fbff;
  border-radius: 10px;
  border: 1px dashed #5f5f5f;
  margin: 0 auto;
  padding: 21px;
}
.course_wrapper1 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  row-gap: 22px;
  column-gap: 52px;
}
.course_wrapper1 input:focus-visible {
  outline: 1px solid var(--greenish) !important;
}

.account-profile {
  display: grid;
  grid-template-columns: 1fr 1fr ;
  row-gap: 42px;
  column-gap: 52px;
}
.account-profile input:focus-visible {
  outline: 1px solid var(--greenish) !important;
}

.modal_content {
  max-height: calc(650px - 200px);
  overflow-y: scroll;
}
.modal_content::-webkit-scrollbar {
  width: 10px !important;
  display: block !important;
  overflow-y: scroll !important;
  background-color: #00000034;
  overflow: visible !important;
}
.modal_content {
  max-height: calc(650px - 200px);
  overflow-y: auto;
}

/* Customize scrollbar */
.modal_content::-webkit-scrollbar {
  width: 10px !important; /* Width of the scrollbar */
  display: block !important;
  overflow-y: scroll !important;
  border-radius: 5px;
  background-color: #ceb6b634;
}

.modal_content::-webkit-scrollbar-thumb {
  background-color: var(--skyColor);
  border-radius: 5px;
}
.modal_cross {
  left: 7px;
}
.phone_wrapper_session .PhoneInput {
  border: 1px solid white !important;
  /* margin-bottom: 17px; */
}
.phone_wrapper_session .PhoneInputCountry {
  margin: 0;
  background-color: white;
  padding: 12px;
}
.phone_wrapper_session .PhoneInputInput {
  padding: 8px 10px;
  border: none !important;
  /* margin-bottom: 17px; */
}
.phone_wrapper_session .PhoneInput--focus:focus-within {
  outline: 1px solid var(--greenish) !important;
}
.phone_wrapper_session .PhoneInput:focus-visible {
  border: none !important;
  /* margin-bottom: 17px; */
}
.phone_wrapper_session .PhoneInput--focus :focus-visible {
  outline: none !important;
}
input[value=""],
::placeholder {
  color: #a3a3a3 !important;
}
input[value=""]:focus-within {
  outline: 1px solid var(--greenish) !important;
}
textarea:focus-within {
  outline: 1px solid var(--greenish) !important;
}

/* table loader  */
.table-loader {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.table-loader div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: var(--greenish);
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.table-loader div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.table-loader div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.table-loader div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.table-loader div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
/* table loader  end */

.tiny-loader {
  display: inline-block;
  position: relative;
  top: -44px;
  right: 13px;
}
.tiny-loader div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: var(--greenish);
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.tiny-loader div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.tiny-loader div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.tiny-loader div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.tiny-loader div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}

.image-container {
  position: relative;
  width: 300px; /* Adjust width as needed */
  overflow: hidden;
  border-radius: 4px;
}

.image {
  width: 100%;
  height: auto;
  display: block;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Adjust opacity as needed */
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.1;
}

.text {
  color: white;
  font-size: 24px;
  text-align: center;
  padding: 20px;
}

.senssion_wrapper {
  max-width: 500px;
  margin: 0 auto;
}
.ribbon {
  top: -21px;
  right: -5px;
  position: absolute;
}
button.page-link:focus {
  box-shadow: none !important;
}
.active > .page-link {
  background-color: white !important;
  border: 1px solid var(--greenish) !important;
  color: var(--greenish) !important;
}

.line-clamp-para a {
  color: var(--greenish) !important;
}
.img_outer_wrapper {
  background: linear-gradient(
    180deg,
    rgba(97, 190, 176, 0.5494572829131652) 16%,
    rgba(255, 255, 255, 0.7399334733893557) 100%
  );
  padding: 42px 42px 0px 42px;
  border-radius: 12px;
}
.blog_img_wrapper img {
  border-radius: 12px;
}
.blog_img_wrapper img {
  height: 546px !important;
  object-fit: cover;
  object-position: center;
}


.horizontal-slider {
  width: 100%;
  max-width: 500px;
  height: 58px;
}

.vertical-slider {
  height: 380px;
  width: 50px;
  border: 1px solid grey;
}

.example-thumb {
  font-size: 0px;
  text-align: center;
  color: var(--skyColor);
  cursor: pointer;
  border: 5px solid var(--skyColor);
  box-sizing: border-box;
}

.example-thumb.active {
  background-color: var(--skyColor);
}

.example-track {
  position: relative;
  background: #ffff;
  border-radius: 12px;
}

.example-track.example-track-1 {
  background: var(--skyColor);
}

.example-track.example-track-2 {
  background: #fff;
  border-radius: 12px;
}

.horizontal-slider .example-track {
  top: 20px;
  height: 10px;
}

.horizontal-slider .example-thumb {
  top: 1px;
  width: 10px;
  height: 49px;
  line-height: 38px;
  border-radius: 12px;
  background-color: var(--skyColor);
}

.horizontal-slider .example-mark {
  margin: 0 calc(25px - 6px);
  bottom: calc(50% - 6px);
}

.vertical-slider .example-thumb {
  left: 1px;
  width: 48px;
  line-height: 40px;
  height: 50px;
}

.vertical-slider .example-track {
  left: 20px;
  width: 10px;
}

.range-year {
  text-align: center;
  background: var(--skyColor);
  border-radius: 4px;
  min-width: 49px;
  min-height: 38px;
  font-family: Nunito, sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 14.32px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 11px;
}

.range-validate {
  font-family: Nunito, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 14.32px;
  color: #6c6c6c;
}
.errors-msg{
  bottom: -22px;
}

.slider-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.label-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.slider-wrapper {
  width: 80%; /* Adjust width as needed */
}

.course-title-icon {
  height: 40px !important;
  width: 40px !important;
}

.loading-icon {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


@media (max-width: 1199px) {
  .dropdown-box {
    position: relative;
    top: 0;
    background: transparent !important;
    box-shadow: none !important;
    padding: 4px 0px 4px 18px !important;
    border-top: 1px solid #8080802e;
    margin-top: 5px;
    max-width: 100% !important;
  }
}

@media (max-width: 500px) {
  .banner-wrapper h1 {
    line-height: normal;
  }

  .video_testimonial .slick-prev {
    left: -30px !important;
  }
  .video_testimonial .slick-next {
    right: -30px !important;
  }

  .partner_logos {
    grid-template-columns: repeat(1, 1fr) !important;
  }

  .partner_logos img {
    height: 75px;
  }

  .event-grid-wrapper {
    grid-template-columns: 1fr;
  }

  .event-desc {
    gap: 0px;
  }

  .book_event_button {
    bottom: 10px;
    right: 14px;
  }
  .aboutWrapper > div:first-child {
    padding-top: 21px !important;
  }
  .login_wrapper {
    min-height: calc(100vh - 590.86px);
  }
}

@media (max-width: 767px) {
  .blog_img_wrapper img {
    height: 253px !important;
  }
  .login_wrapper {
    min-height: calc(100vh - 333.27px);
  }
  .aboutWrapper > div:first-child {
    padding-top: 21px !important;
  }
  .alt_ContentImgEven > .row {
    flex-direction: column-reverse !important;
  }

  .about-img {
    max-width: 100%;
    height: auto;
    padding: 12x;
  }

  .banner-wrapper h1 {
    line-height: normal;
  }
  .video_testimonial .slick-prev {
    left: -30px !important;
  }
  .video_testimonial .slick-next {
    right: -30px !important;
  }
  .partner_logos1 {
    display: none;
  }
  .partner_logos {
    display: none;
  }
  .partner_logos_mobile img {
    width: auto;
    height: 59px;
    margin: auto;
  }

  .partner_logos_mobile {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    row-gap: 35px;
    column-gap: 0;
  }

  .WhyStudy_cards {
    grid-template-columns: repeat(1, 1fr);
  }

  .event-grid-wrapper {
    grid-template-columns: 1fr;
  }

  .event-desc {
    gap: 0px;
  }

  .book_event_button {
    bottom: 10px;
    right: 14px;
  }
  .video_card {
    max-width: 80%;
  }

  .video_card .slick-list {
    margin-left: 5px;
  }

  .bg_banner_color {
    padding-top: 300px;
  }

  .grey-bg {
    width: 100% !important;
  }

  .grey-bg:before {
    left: 0 !important;
    width: 120% !important;
    height: 120% !important;
    z-index: 0 !important;
    transform: skewY(-10deg) !important;
  }
  .cookie-banner {
    bottom: 0px;
    right: 0px;
    max-width: 100%;
  }
  .img_outer_wrapper {
    padding: 22px 22px 0px 22px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .blog_img_wrapper img {
    height: 253px !important;
  }
  .aboutWrapper > div:first-child {
    padding-top: 21px !important;
  }
  .img_outer_wrapper {
    padding: 22px 22px 0px 22px;
  }
  .alt_ContentImgEven > .row {
    flex-direction: column-reverse !important;
  }

  .video_card .slick-list {
    margin-left: 5px;
  }

  .banner-wrapper {
    padding: 37px 0px;
  }

  .about-img {
    max-width: 100%;
    height: auto;
    background-size: contain;
  }
  .partner_logos1 {
    display: none;
  }
  .partner_logos {
    display: none;
  }
  .partner_logos_mobile img {
    width: auto;
    height: 85px;
    margin: auto;
  }

  .partner_logos_mobile {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 35px;
    column-gap: 0px;
  }

  .event-grid-wrapper {
    grid-template-columns: 1fr 1fr;
  }

  .book_event_button {
    bottom: 28px;
    right: 21px;
  }

  .event-desc {
    gap: 0px;
  }

  .grey-bg {
    width: 53%;
  }
  .admin_details_card {
    grid-template-columns: 1fr 1fr;
    top: 37px;
    gap: 17px;
  }
  .blog_wrapper .empty_data {
    height: 100% !important;
  }
}

@media (min-width: 992px) and (max-width: 1024px) {
  .aboutWrapper > div:first-child {
    padding-top: 21px !important;
  }
  .alt_ContentImgEven > .row {
    flex-direction: column-reverse !important;
  }

  .about-img {
    max-width: 100%;
    height: auto;
  }

  .partner_logos1 {
    display: none;
  }
  .partner_logos {
    display: none;
  }
  .partner_logos_mobile img {
    width: auto;
    height: 85px;
    margin: auto;
  }

  .partner_logos_mobile {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 35px;
    column-gap: 0px;
  }

  .event-grid-wrapper {
    grid-template-columns: 1fr 1fr;
  }

  .event-desc {
    gap: 0px;
  }
  .admin_details_card {
    grid-template-columns: 1fr 1fr;
    top: 37px;
    gap: 17px;
  }
}

@media (min-width: 1025px) and (max-width: 1300px) {
  .about-content p {
    line-height: 25px !important;
  }
  .partner_logos1 {
    display: none;
  }
  .partner_logos {
    display: none;
  }
  .partner_logos_mobile img {
    width: auto;
    height: 95px;
    margin: auto;
  }

  .partner_logos_mobile {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 35px;
    column-gap: 0px;
  }
  .event-desc {
    gap: 0px;
  }
  .grid_search {
    grid-template-columns: 1fr 1fr;
  }
  .grid_course_search{
    grid-template-columns: 1fr 1fr;
  }
  .stats_logos {
    grid-template-columns: repeat(3, 1fr) !important;
  }
  .message_textSize {
    font-size: 14px !important;
  }
  .contact_wrapper {
    row-gap: 22px;
    column-gap: 12px;
  }
  .admin_details_card {
    grid-template-columns: 1fr 1fr;
    top: 37px;
    gap: 17px;
  }
}

@media (min-width: 1301px) and (max-width: 1500px) {
  .stats_logos {
    grid-template-columns: repeat(4, 1fr) !important;
  }
  .partner_logos {
    display: flex;
  }

  .document_wrapper > div {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 23px;
  }
  .admin_details_card {
    grid-template-columns: 1fr 1fr;
    top: 37px;
    gap: 17px;
  }
  .partner_logos_mobile img {
    display: none;
  }

  .partner_logos_mobile {
    display: none;
  }
}

@media (min-width: 1300px) and (max-width: 2400px) {
  .partner_logos_mobile img {
    display: none !important;
  }

  .partner_logos_mobile {
    display: none !important;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1200px)  {
  .cookie-banner{
  bottom: 50%!important;
      right: 50%!important;
      transform: translate(50%, -50%)!important;}
  }

.error-border {
  border: 1px solid #f76264!important;
}




/* ---------------------model-css---------------------------------------- */


.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5)!important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-dialog {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  max-width: 500px;
  width: 100%;
  position: absolute!important;
  top: 30px!important;
}


